import { netpost, get } from '../plugins/request'

export default {
  // 创建分享
  createShareWaybill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/createShareWaybill`, params)
  },
  // 验证分享链接
  verifyShareWaybillUrl(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/VerifyShareWaybillUrl`, params)
  },
  // 分享运单信息
  getShareWaybill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetShareWaybill`, params)
  },
  // 通过运单id获取运单商品
  getWaybillGoods(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetWaybillGoods', params)
  },
  // 通过运单号获取快递公司
  getDeliveryCompany(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetDeliveryCompany', params)
  },
  // 运单跟踪
  getTraces(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetTraces', params)
  },
  // 分享记录
  getWaybillShareList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetWaybillShareList', params)
  },
  // 代理商分享记录
  getWaybillAgencyShareList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/GetWaybillAgencyShareList', params)
  },
  // 禁用运单分享
  disableWaybillList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/DisableWaybillList', params)
  },
  // 禁用运单分享
  disableWaybillListAgency(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/DisableWaybillListAgency', params)
  },
  // 记录运单分享点击次数（同一次浏览 session，同一code只记录一次 防止频繁刷新）
  clickWaybillShare(params, loading = true) {
    Object.assign(params, { loading })
    return netpost('/api/Logistics/ClickWaybillShare', params)
  },
  // 生成短链接
  getShareShortUrl(params, loading = true) {
    // &expireDate=2020-04-07
    Object.assign(params, { loading })
    return get( "http://api.3wt.cn/api.htm?format=jsonp&url=" +
      encodeURIComponent(params.url) +
      "&key=5df9f40d6aee8a4c08d54cc8@e60d2dc4b099e986a7a34b8f5c982bd1&domain=4")
  },
}
