<!-- 运单分享设置完成 -->
<template>
  <div class="share-finish">
    <div class="sf-container">
      <div class="title">
        <i class="el-icon-success"></i>
        成功创建分享链接
      </div>
      <div class="container">
        <div class="url-box">
          <div class="url-content">
            <div>
              <el-input v-model="url" size="small" readonly=""></el-input>
            </div>
            <div class="pwd">
              <span>分享码：</span
              ><el-input
                v-model="pwd"
                size="small"
                style="width: 100px"
                readonly=""
              ></el-input>
            </div>
            <div>
              链接<span class="day">{{ day }}</span>后过期
            </div>
          </div>
          <div>
            <el-button type="primary" size="small" @click="copyLinkText"
              >复制链接及分享码</el-button
            >
          </div>
        </div>
        <div class="qrcode-box" ref="savecontent" >
          <template v-if="shareData.waybillList">
            <div v-for="item in shareData.waybillList"  :key="item.id">
              <span v-if="item.deliveryno">{{ item.consignee }} {{ item.deliveryno }}</span>
            </div>
          </template>
          <div class="qrcode">
            <canvas id="canvas" style="display: none"></canvas>
          </div>
          <img
            :src="imgUrl"
            id="qr-img"
            data-clipboard-text="https://example.com/image.jpg"
          />
          <div class="qrcode-note">
            本二维码有效期止<span class="day">{{ day }}</span
            ><br />微信扫码即可查看
          </div>
          <div class="but" v-if="!saveLoading">
            <el-button type="primary" size="small" @click="copyImg"
              >复制二维码</el-button
            >
          </div>
          <div class="small">本查询仅供参考，不作为任何法律依据</div>
          <div class="small" v-if="!saveLoading">已含分享码，扫码后无需再次输入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import QRCode from 'qrcode'
import Clipboard from 'clipboard'
import WaybillShare from 'api/waybill-share'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
export default {
  components: { QRCode },
  name: '',
  props: {
    shareData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      saveLoading:false,
      imgUrl: '',
      url: '',
      pwd: '',
      day: ''
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.shareData && this.shareData.code) {
      this.url = `https://i.199.jp/waybill/share/details/${this.shareData.code}`
      this.pwd = this.shareData.pwd
      // this.day = this.shareData.validity_day
      this.day = dayjs(this.shareData.overdue_time).format('YYYY-MM-DD HH:mm:ss')
      // WaybillShare.getShareShortUrl({url:'http://www.baidu.com'}).then(res => {
      //   console.log(res)
      // })
    }
    console.log(this.shareData, 'share data')
  },
  mounted() {
    this.useqrcode() //生成二维码
  },
  destroyed() {},
  methods: {
    useqrcode() {
      let _qrurl = this.url + '?pwd=' + this.pwd
      //生成二维码
      let canvas = document.getElementById('canvas')
      QRCode.toCanvas(canvas, _qrurl, function (error) {
        if (error) {
          console.error(error)
        } else {
          console.log('success!')
        }
      })
      this.saveImg() //保存图片
    },
    //保存图片
    saveImg() {
      let myCanvas = document.getElementsByTagName('canvas')
      this.imgUrl = myCanvas[0].toDataURL('image/png')
    }, // 复制二维码
    copyImg() {
      // var canvas = document.createElement('canvas') // 创建一个画板
      // let image = new Image()
      // image.setAttribute('crossOrigin', 'Anonymous') //可能会有跨越问题
      // image.src = this.imgUrl
      // image.onload = () => {
      //   // img加载完成事件
      //   canvas.width = image.width + 100 //设置画板宽度
      //   canvas.height = image.height + 100 //设置画板高度
      //   let ctx = canvas.getContext('2d')
      //   ctx.font = '14px Arial'
      //   ctx.fillText('测试', 10, 30)
      //   ctx.drawImage(image, 0, 40) //加载img到画板
      //   let url = canvas.toDataURL('image/png') // 转换图片为dataURL，格式为png
      //   this.clipboardImg(url) // 调用复制方法
      // }
      this.saveLoading = true
      this.$nextTick(() => {
        html2canvas(this.$refs.savecontent, {
          backgroundColor: null,
        }).then((canvas) => {
          let url = canvas.toDataURL("image/png");
          this.clipboardImg(url) 
          this.saveLoading = false
        });
      })
    },
    // 从url下载并复制图片，如果是png格式的图片直接调用此方法就能复制成功
    async clipboardImg(url) {
      try {
        const data = await fetch(url)
        const blob = await data.blob()
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob
          })
        ])
        this.$message.success('已复制')
      } catch (err) {
        this.$message.error('复制失败，可在二维码图片右键保存图片')
      }
    },

    copyLinkText() {
      const input = document.createElement('input')
      document.body.appendChild(input)
      let _copyTxt = ''
      if (this.shareData && this.shareData.auto_pwd) {
        _copyTxt = this.url + '?pwd=' + this.pwd + ' 分享码:' + this.pwd
      } else {
        _copyTxt = this.url + ' 分享码:' + this.pwd
      }
      input.setAttribute('value',_copyTxt)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$message.success('已复制')
      }
      document.body.removeChild(input)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.share-finish {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;
  .sf-container {
    padding: 20px;
    .title {
      color: #4d1c8a;
    }
    .container {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      .url-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        .url-content {
          display: flex;
          flex-direction: column;
          padding-right: 20px;
          div {
            word-break: break-all;
            margin-bottom: 10px;
          }
          .pwd {
            display: flex;
            span {
              white-space: nowrap;
              margin-top: 6px;
            }
          }
          .day {
            color: #4d1c8a;
            font-weight: bold;
          }
        }
      }
      .qrcode-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        border-left: 1px solid #f5f5f5;
        .qrcode-note {
          text-align: center;
          .day {
            color: #4d1c8a;
            font-weight: bold;
          }
        }
        .but {
          margin: 20px 0;
          text-align: center;
        }
        .small {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>
